


























































































































































































































































import { ErrorManager } from '@/models/error';
import { Shipment } from '@/models/shipment';
import { mapActions, mapState } from 'vuex';
import PackageForm from '../PackageForm.vue';
import PackageStatusDialog from '../PackageStatusDialog.vue';
import dateFormat from '@/utils/dateFormat';
import PackageInformationDialog from '../PackageInformationDialog.vue';
import StaffPackageManagerMobile from '../StaffPackageManagerMobile.vue';
import { searchItems } from '@/utils/functions';
import auth from '@/mixins/auth';
import StaffUnassignedPackageForm from './StaffUnassignedPackageForm.vue';
import StaffUnassignedPackageMobile from './StaffUnassignedPackageMobile.vue';
import { cloneDeep } from 'lodash';

export default auth.extend({
  components: {
    PackageStatusDialog,
    PackageForm,
    PackageInformationDialog,
    StaffPackageManagerMobile,
    StaffUnassignedPackageForm,
    StaffUnassignedPackageMobile
  },
  name: 'StaffUnassignedPackageLIst',
  data() {
    return {
      loading: false,
      dialog: false,
      snackbar: false,

      errorMessage: '',
      search: '',

      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        {
          text: '',
          sortable: false,
          align: 'start',
          width: '20px',
          value: 'data-table-space'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Created At',
          value: 'created_at'
        },
        {
          text: 'Tracking Number',
          value: 'tracking_number'
        },
        {
          text: 'Package Description',
          value: 'description'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Weight',
          value: 'weight'
        }
      ]
    };
  },
  computed: {
    ...mapState('shipment', ['unassignedShipment']),

    screenWidth() {
      return window.innerWidth > 600;
    },

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    packageHeaders(): any {
      const headerList = cloneDeep(this.headers);
      if (!this.isUserMainBranch) {
        headerList.push({
          text: '',
          value: 'actions'
        });
      }
      return headerList;
    },

    filterShipment(): Shipment[] {
      let shipmentWithFullName = this.unassignedShipment.map(
        (unassignedShipment: any) => {
          unassignedShipment.name = `${unassignedShipment.first_name} ${unassignedShipment.last_name}`;
          return unassignedShipment;
        }
      );

      shipmentWithFullName = searchItems<Shipment>(
        shipmentWithFullName,
        this.search
      );

      return shipmentWithFullName;
    }
  },

  watch: {
    search: {
      handler(value) {
        //if mobile view
        if (!this.screenWidth) {
          this.filterShipment;
        }
      }
    }
  },

  methods: {
    ...mapActions({
      fetchUnassignedShipments: 'shipment/fetchUnassignedShipments'
    }),

    dateFormat,

    openDialog(item: Shipment) {
      (this as any).$refs.staffUnassignedPackageForm.open(item);
    },

    openPackageInformation(item: Shipment) {
      (this as any).$refs.packageinformationdialog.open(item, true);
    }
  },

  async mounted() {
    this.loading = true;
    this.fetchUnassignedShipments()
      .catch((error: any) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
});
